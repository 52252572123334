<template>
  <edit-template @confirm="submit" :confirm-loading="loading" flag="edit" :data-loading="dataLoading">
    <el-form :model="form" ref="form" label-width="auto" :rules="rules">
      <el-form-item label="收费方案名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入" :maxlength="20"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="方案分类" prop="category">{{form.category_text}}</el-form-item>
        </el-col>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="业务类型" prop="type">{{form.type_text}}</el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="缴费方式" prop="mode">{{form.mode_text}}</el-form-item>
        </el-col>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="入账方式" prop="income_method">
            <el-select v-model="form.income_method" placeholder="请选择">
              <el-option v-for="item in income_method" :key="item.id" :label="item.income_method_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :sm="8" :xs="8" v-if="form.income_method === 2">
          <el-form-item label="入账校区" prop="school_id">
            <el-select v-model="form.school_id" placeholder="请选择">
              <el-option v-for="item in schools" :key="item.id" :label="item.school_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="combination">
        <span slot="label" style="font-size: 20rem;color: #1E3AA2;">收费计划</span>
      </el-form-item>
      <el-form-item label="总金额">
        {{form.total_amount}}
      </el-form-item>
    </el-form>
    <div class="plan">
      <plan class="plan-item" v-for="(item,index) in form.combination" :data="item" :key="index" :handle="false"></plan>
    </div>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit"
import plan from "./components/plan"
export default {
  _config: {route: {path: "edit", meta: {title: "编辑"}}},
  components: {EditTemplate,plan},
  data() {
    return {
      // 加载状态
      loading: false,
      // 初始数据加载状态
      dataLoading:false,
      // 入账校区选项
      schools:[],
      income_method:[],
      // 编辑索引
      index:-1,
      // 表单数据
      form: {
        name:null,
        school_id:'',
        combination:[],
        type: '',
        mode: '',
        category: '',
        income_method: '',
        total_amount:0,//总金额
      },
      // 入账校区固定数据
      fixedSchool:[],
      // 表单验证规则
      rules: {
        name:[{required:true,message:"请输入收费方案名称",trigger:"blur"}],
        income_method:[{required:true,message:"请选择入账方式",trigger:"change"}],
        school_id:[{required:true,message:"请选择入账校区",trigger:"blur"}],
      },
      pickerOptions:{
        disabledDate(e){
          let now = (new Date()).getFullYear();
          return Math.abs(now - e.getFullYear()) > 3;
        }
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataLoading = true;
      this.$_register.get("api/finance/fee-plan/get-check").then(res=>{
        let {school,income_method} = res.data.data;
        this.schools = school;
        this.$_register.get("api/finance/fee-plan/show?id="+this.$route.query.id).then(res=>{
        let data= res.data.data;
        const { school_id, type, ...other } = data
        this.form = {...other,school_id: school_id || '' , type};
        const arr = [[0,1,2], [1], [0,2]]
        const arr2 = arr[type] || []
        const list = income_method.filter(item=>arr2.includes(item.id))
        this.income_method = list;
      }).finally(()=>this.dataLoading = false);
      }).catch(()=>this.dataLoading=false)

    },
    submit() {
      this.$refs.form.validate(flag=>{

        if (flag){
          this.loading = true;
          const { income_method, school_id } = this.form
          let data = {
            id:this.$route.query.id,
            name:this.form.name,
            income_method,
            school_id:income_method===2 ? school_id : 0
          }
          this.$_register.post("/api/finance/fee-plan/update",data).then(res=>{
            this.$message.success(res.data.msg);
            this.$router.back();

          }).finally(()=>this.loading = false);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.plan{
  display: grid;
  grid-gap: 48rem;
  grid-template-columns: repeat(4,1fr);
}
</style>
